import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of, ReplaySubject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";

import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { HttpClient } from "@angular/common/http";
import { User, UserProfile } from "../models/users";
import { environment } from "../../environments/environment";

@Injectable()
export class UserService {
  baseUrl = environment.apiUrl;
  companyId = environment.companyId;

  currentUserSource = new ReplaySubject<User>(1);
  currentUser$: Observable<any> = this.currentUserSource.asObservable();

  // Save logged in user data
  userData: any;
  // Source data
  private _isLogin = new BehaviorSubject(false);
  // Observable data
  $isAuthenticated: Observable<boolean> = this._isLogin.asObservable();

  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    public afStore: AngularFirestore
  ) {
    this.afAuth.authState.subscribe((user) => {
      //console.log("UserService/constructor:", user);

      this.userData = user ? user : null;
      this.configureAuthState(user);
    });
  }

  private configureAuthState(firebaseUser): void {
    ////console.log("UserService/configureAuthState/:", firebaseUser);
    if (firebaseUser) {
      firebaseUser.getIdToken().then(
        (theToken) => {
          localStorage.setItem("token", theToken);
          this._isLogin.next(true);

          this.getUserData(firebaseUser.uid);
        },
        (failReason) => {
          ////console.log("UserService/configureAuthState/Not found a token:");
          this.doSignedOutUser();
        }
      );
    } else {
      this.doSignedOutUser();
    }
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    const value = token !== null && token != "" ? true : false;
    //console.log("UserService/isLoggedIn():", value);
    return value;
  }

  uid(): string {
    const uid = this.userData && this.userData.uid ? this.userData.uid : "";
    //console.log("UserService/uid():", uid);
    return uid;
  }

  getCurrentUser(): Observable<User> {
    //console.log("UserService/getCurrentUser/userData:", this.userData);

    return this.isLoggedIn() ? this.currentUser$ : new BehaviorSubject(null);
  }

  saveUserData(uid, email, displayName) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
      `users/${uid}`
    );
    const userData: UserProfile = {
      uid: uid,
      email: email,
      displayName: displayName,
      photoURL:
        "https://kasoft-learning.s3.ap-southeast-1.amazonaws.com/Profile/default_avatar.jpg",
      role: "Fresher",
      //companyId: this.companyId,
      companyId: uid,
    };
    userRef.set(userData, {
      merge: true,
    });

    //Sync user to local
    userData.fsId = uid;
    //this.syncUser(userData);
  }

  //FireAuth
  // Sign in with email/password
  signIn(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        //console.log("UserService/signIn:", result.user.uid);
        this.getUserData(result.user.uid);
      })
      .catch((error) => {
        //window.alert(error.message);
        throw error;
      });
  }

  signOut() {
    return this.afAuth.signOut().then(() => {
      this.doSignedOutUser();
    });
  }

  resetPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email).catch((error) => {
      throw error;
    });
  }

  private getUserData(uid) {
    //console.log("UserService/getUserData/uid:", uid);

    const userRef = this.afStore.doc(`users/${uid}`).valueChanges();
    userRef.subscribe((us) => {
      const userFb: User = {
        uid: us["uid"],
        email: us["email"],
        displayName: us["displayName"],
        photoURL: us["photoURL"],
        role: us["role"],
      };

      this.currentUserSource.next(userFb);
    });
  }

  private doSignedOutUser() {
    //console.log("doSignedOutUser");
    localStorage.removeItem("token");
    this.userData = null;
    this._isLogin.next(false);
    this.currentUserSource.next(null);
  }

  private verifyTokenAPI(theToken) {
    this.http
      .post(this.baseUrl + "/FirebaseAuth/VerifyToken", {
        token: theToken,
      })
      .subscribe({
        next: () => {
          ////console.log("UserService/verifyTokenAPI/success");
        },
        error: (err) => {
          ////console.log("UserService/verifyTokenAPI/err:", err);
          this.doSignedOutUser();
        },
      });
  }

  private syncUser(user) {
    this.http.post(this.baseUrl + "/FirebaseAuth/Sync", user).subscribe({
      next: () => {
        ////console.log("UserService/syncUser/success");
      },
      error: (err) => {
        ////console.log("UserService/syncUser/err:", err);
      },
    });
  }

  private getToken(): string {
    return localStorage.getItem("token");
  }
}
