import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NbComponentStatus,
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbTagComponent,
  NbTagInputAddEvent,
  NbToastrService,
} from "@nebular/theme";
import { remove } from "lodash";
import { Customer } from "../../models/customer.model";
import EnumItem from "../../models/enum-list.model";
import { CustomerService } from "../../services/customer.service";

@Component({
  selector: "ngx-dialog-customer",
  templateUrl: "dialog-customer.component.html",
  styleUrls: ["dialog-customer.component.scss"],
})
export class DialogCustomerComponent implements OnInit {
  @Input() header: string;
  @Input() mode: string;
  @Input() customer: Customer = new Customer();

  status: string = "0";
  trees: Set<string> = new Set([]);
  diaForm: FormGroup;
  submitted = false;
  loading = false;
  edited = false;

  constructor(
    protected ref: NbDialogRef<DialogCustomerComponent>,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    const fullNameValidators = [
      Validators.minLength(3),
      Validators.maxLength(250),
      Validators.required,
    ];

    const mobileValidators = [
      Validators.minLength(9),
      Validators.maxLength(11),
      Validators.required,
    ];

    const stringValidators = [
      Validators.minLength(3),
      Validators.maxLength(500),
    ];

    this.diaForm = this.fb.group({
      fullName: ["", fullNameValidators],
      description: ["", stringValidators],
      dob: [Validators.required],
      gender: [],
      mobile: ["", mobileValidators],
      cardId: [Validators.required],
      email: [],
      address: ["", stringValidators],
    });

    if (this.customer.tags && this.customer.tags != "") {
      var trees = new Set([]);
      var tags = JSON.parse(this.customer.tags);
      tags.forEach(function (value) {
        trees.add(value.name);
      });
      this.trees = trees;
    } else {
      this.trees.add("KH");
    }

    if (!this.customer.gender) {
      this.customer.gender = "Khác";
    }
  }

  cancel() {
    if (this.edited) {
      this.ref.close(this.customer);
    } else {
      this.ref.close();
    }
  }

  submit() {
    this.submitted = true;

    var tags: EnumItem[] = [];
    this.trees.forEach(function (item) {
      var tag = new EnumItem();
      tag.name = item;
      tags.push(tag);
    });
    this.customer.tags = JSON.stringify(tags);
    this.customer.email = this.customer.email ? this.customer.email : "";
    this.customer.mobile = this.customer.mobile
      ? this.customer.mobile.replace(" ", "")
      : "";

    this.customer.cardId = this.customer.cardId ? this.customer.cardId : "";
    this.customer.photoURL = this.customer.photoURL
      ? this.customer.photoURL
      : "";
    this.customer.fullName = this.customer.fullName
      ? this.customer.fullName
      : "";
    if (this.customer.fullName != "") {
      this.customer.lastName = this.customer.fullName.split(" ")[0].trim();
      this.customer.firstName = this.customer.fullName
        .replace(this.customer.lastName, "")
        .trim();
    }
    // this.customer.dob = this.customer.dob
    //   ? this.customer.dob
    //   : new Date().toDateString();
    this.customer.dobYear = this.customer.dobYear
      ? this.customer.dobYear
      : new Date().getFullYear();

    this.customer.gender = this.customer.gender ? this.customer.gender : "Khác";
    this.customer.address = this.customer.address ? this.customer.address : "";
    this.customer.description = this.customer.description
      ? this.customer.description
      : "";
    this.customer.tags = this.customer.tags ? this.customer.tags : "";
    this.customer.imagesUrl = this.customer.imagesUrl
      ? this.customer.imagesUrl
      : [];

    if (this.mode == "edit") {
      this.edit();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.submitted = true;

    console.log("create:", this.customer);

    this.customerService
      .create(this.customer)
      .then((data) => {
        this.customer = data;

        this.loading = false;
        this.submitted = false;

        this.ref.close(this.customer);
      })
      .catch((err) => {
        this.loading = false;
        this.submitted = false;
      });
  }

  edit() {
    this.loading = true;
    this.submitted = true;

    this.customerService
      .update(this.customer)
      .then((data) => {
        this.loading = false;
        this.submitted = false;

        this.edited = true;
        this.ref.close(this.customer);
        this.showToast("success", "Cập nhật bài viết", "thành công");
      })
      .catch((err) => {
        this.loading = false;
        this.submitted = false;
        this.showToast("warning", "Cập nhật bài viết", "thất bại");
      });
  }

  //Images
  addImage(imageURL: string) {
    if (!this.customer.photoURL) {
      this.customer.imagesUrl = [];
    }
    this.customer.imagesUrl.push(imageURL);
  }

  deleteImage(item) {
    remove(this.customer.imagesUrl, (el) => {
      return el == item;
    });
  }

  //Tags
  onTagRemove(tagToRemove: NbTagComponent): void {
    this.trees.delete(tagToRemove.text);
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.trees.add(value);
    }
    input.nativeElement.value = "";
  }

  //CKEditor
  loadingContentEditor = true;
  public editorConfigBasic = {
    extraPlugins: "autogrow",
    autoGrow_onStartup: true,
    autoGrow_minHeight: 50,
    autoGrow_maxHeight: 600,
    autoGrow_bottomSpace: 10,
    toolbar: [["Format", "FontSize", "TextColor", "BGColor", "Image", "Table"]],
  };

  // control event
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 2000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, titleContent, config);
  }
}
