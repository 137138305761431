import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from "@angular/fire/firestore";

import * as firebase from "firebase/app";
import "firebase/firestore";

import { environment } from "../../environments/environment";
import { History } from "../models/history.model";
import { User } from "../models/users";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class HistoryService {
  visibility = environment.visibility;
  userLogin: User;
  private dbPath = "/histories";

  collRef: AngularFirestoreCollection<History>;

  constructor(private db: AngularFirestore, private userStore: UserService) {
    this.collRef = db.collection(this.dbPath);

    this.userStore.getCurrentUser().subscribe((user: User) => {
      if (user) {
        this.userLogin = user;
        this.visibility = user.companyId
          ? user.companyId
          : environment.visibility;
      }
    });
  }

  getHistories(companyId: string, limit?: number, cursor?: any): any {
    var db = firebase.default.firestore();
    var ref = db
      .collection(this.dbPath)
      .where("isDeleted", "==", false)
      .where("isActive", "==", true)
      .where("visibility", "==", companyId)
      .orderBy("updatedOn", "desc");

    ref = limit ? ref.limit(limit) : ref.limit(10);

    if (cursor) {
      ref = ref.startAfter(cursor);
    }

    return ref.get();
  }

  getMyHistories(uid: string, limit?: number, cursor?: any): any {
    var db = firebase.default.firestore();
    var ref = db
      .collection(this.dbPath)
      .where("isDeleted", "==", false)
      .where("isActive", "==", true)
      .where("createdBy", "==", uid)
      .orderBy("updatedOn", "desc");

    ref = limit ? ref.limit(limit) : ref.limit(10);

    if (cursor) {
      ref = ref.startAfter(cursor);
    }

    return ref.get();
  }

  getCustomerHistories(customerId: string, limit?: number, cursor?: any): any {
    var db = firebase.default.firestore();
    var ref = db
      .collection(this.dbPath)
      .where("isDeleted", "==", false)
      .where("isActive", "==", true)
      .where("customerId", "==", customerId)
      .orderBy("updatedOn", "desc");

    ref = limit ? ref.limit(limit) : ref.limit(10);

    if (cursor) {
      ref = ref.startAfter(cursor);
    }

    return ref.get();
  }

  getNewHistories(uid?: string): AngularFirestoreCollection<History> {
    return this.db.collection(this.dbPath, (ref) =>
      ref
        .where("isDeleted", "==", false)
        .where("isActive", "==", true)
        .where("createdBy", "==", uid)
        .orderBy("updatedOn", "desc")
        .limit(10)
    );
  }

  get(id: string) {
    return this.collRef.doc(id).valueChanges();
  }

  create(history: History): any {
    history.isActive = true;
    history.isDeleted = false;
    history.createdOn = new Date();
    history.createdBy = this.userLogin.uid;
    history.updatedOn = new Date();
    history.updatedBy = this.userLogin.uid;
    history.author = this.userLogin;
    history.visibility = this.visibility;

    return this.collRef.add({ ...history }).then((docRef) => {
      history.id = docRef.id;
      return history;
    });
  }

  update(history: History): Promise<void> {
    const updatedOn = new Date();
    const updatedBy = this.userLogin.uid;
    return this.collRef.doc(history.id).update({
      fullName: history.fullName,
      dobYear: history.dobYear,
      issueDate: history.issueDate,
      status: history.status,
      value: history.value,
      description: history.description,
      imagesUrl: history.imagesUrl,
      updatedOn: updatedOn,
      updatedBy: updatedBy,
    });
  }

  delete(id: string): Promise<void> {
    const updatedOn = new Date();
    const updatedBy = this.userLogin.uid;
    return this.collRef.doc(id).update({
      isDeleted: true,
      updatedOn: updatedOn,
      updatedBy: updatedBy,
    });
  }
}
