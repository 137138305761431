import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "ngx-view-video",
  styleUrls: ["./view-video.component.scss"],
  templateUrl: "./view-video.component.html",
})
export class ViewVideoComponent implements OnInit {
  @ViewChild("player") player: any;
  videoId = "";

  @Input() header: string;
  @Input()
  set id(id: string) {
    this.videoId = id;
  }

  @Input()
  set url(url: string) {
    if (url && url != "") {
      this.videoId = this.getYouTubeId(url);
    }
  }

  constructor() {}

  ngOnInit(): void {
    if (!this.header) this.header = "";
  }

  // Autoplay
  onReady() {
    this.player.mute();
    this.player.playVideo();
  }

  // Loop
  onStateChange(event) {
    if (event.data === 0) {
      this.player.playVideo();
    }
  }

  getYouTubeId(url) {
    var Id = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      var tmp = url[2].split(/[^0-9a-z_\-]/i);
      if (tmp) Id = tmp[0];
    } else {
      Id = url;
    }
    return Id;
  }
}
