import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { NbAuthJWTInterceptor, NbAuthModule } from "@nebular/auth";
import {
  NbFirebaseAuthModule,
  NbFirebasePasswordStrategy,
  NbFirebaseGoogleStrategy,
} from "@nebular/firebase-auth";
import { NgxAuthRoutingModule } from "./auth-routing.module";

//import { FirebaseAPIService } from './firebase-api.service';

import { ThemeModule } from "../@theme/theme.module";
import {
  NbAlertModule,
  NbCardModule,
  NbIconModule,
  NbLayoutModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
} from "@nebular/theme";
import { authOptions } from "./auth.settings";
import { NgxAuthComponent } from "./components/auth.component";
import { AuthGuard } from "./auth.guard";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth.interceptor";
import { ComponentsModule } from "../@components/components.module";
import { NgxRegisterComponent } from "./components/register/register.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxLoginComponent } from "./components/login/login.component";
import { NgxLogoutComponent } from "./components/logout/logout.component";
import { UserService } from "../services/user.service";

const GUARDS = [AuthGuard];
const COMPONENTS = [
  NgxAuthComponent,
  NgxRegisterComponent,
  NgxLoginComponent,
  NgxLogoutComponent,
];
const NB_MODULES = [
  NbIconModule,
  NbLayoutModule,
  NbCardModule,
  NbAlertModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
];

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    ComponentsModule,
    ReactiveFormsModule,

    AngularFireAuthModule,
    AngularFireDatabaseModule,
    NgxAuthRoutingModule,
    NbFirebaseAuthModule,

    ...NB_MODULES,
    NbAuthModule.forRoot(authOptions),
  ],
  declarations: [...COMPONENTS],
  providers: [
    //FirebaseAPIService,
    UserService,
  ],
})
export class NgxAuthModule {
  static forRoot(): ModuleWithProviders<NgxAuthModule> {
    return {
      ngModule: NgxAuthModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ...GUARDS,
      ],
    };
  }
}
