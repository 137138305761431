import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ngx-view-image",
  styleUrls: ["./view-image.component.scss"],
  templateUrl: "./view-image.component.html",
})
export class ViewImageComponent implements OnInit {
  @Input() header: string;
  @Input() imageURL: string;
  @Input() size?: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.imageURL)
      this.imageURL =
        "https://kasoft-learning.s3.ap-southeast-1.amazonaws.com/Courses/cover-default.png";
    if (!this.header) this.header = "";
    if (!this.size) this.size = "medium";
  }
}
