import { User } from "./users";

export class History {
  id?: string;
  customerId?: string;
  fullName?: string;
  dobYear?: number;

  issueDate?: Date;
  status?: string;
  value?: number;
  description?: string;
  imagesUrl?: string[]; //images

  //default
  author?: User;
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
  isDeleted?: boolean;
  isActive?: boolean;
  visibility?: string;
}
