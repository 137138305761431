import { Component, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "ngx-dialog-yesno-prompt",
  templateUrl: "dialog-yesno-prompt.component.html",
  styleUrls: ["dialog-yesno-prompt.component.scss"],
})
export class DialogYesNoPromptComponent {
  @Input() title: string;
  @Input() description?: string;

  constructor(protected ref: NbDialogRef<DialogYesNoPromptComponent>) {}

  cancel() {
    this.ref.close();
  }

  submit(value) {
    this.ref.close(value);
  }
}
