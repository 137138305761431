import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { YouTubePlayerModule } from "@angular/youtube-player";
import {
  NbActionsModule,
  NbBadgeModule,
  NbButton,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSpinnerModule,
  NbAccordionModule,
  NbTagModule,
  NbListModule,
  NbUserModule,
  NbTabsetModule,
  NbFormFieldModule,
  NbInputModule,
  NbCheckboxModule,
  NbSelectModule,
  NbMenuModule,
  NbAlertModule,
  NbLayoutModule,
} from "@nebular/theme";
import { ThemeModule } from "../@theme/theme.module";
import { UploadImageComponent } from "./upload-image/upload-image.component";
import { ViewImageComponent } from "./view-image/view-image.component";
import { ViewVideoComponent } from "./view-video/view-video.component";
import { ViewTimeAgoComponent } from "./view-time-ago/view-time-ago.component";
import { DialogYesNoPromptComponent } from "./dialog-yesno-prompt/dialog-yesno-prompt.component";
import { DialogTitlePromptComponent } from "./dialog-title-prompt/dialog-title-prompt.component";
import { ViewPlaceholderComponent } from "./view-placeholder/view-placeholder.component";
import { ViewGalleryComponent } from "./view-gallery/view-gallery.component";
import { NgxValidationMessageComponent } from "./validation-message/validation-message.component";
import { UserService } from "../services/user.service";
import { DialogCustomerComponent } from "./dialog-customer/dialog-customer.component";
import { ViewCustomersComponent } from "./view-customers/view-customers.component";
import { ViewCustomerComponent } from "./view-customers/view-customer/view-customer.component";
import { CustomerService } from "../services/customer.service";

import { DialogHistoryComponent } from "./dialog-history/dialog-history.component";
import { ViewHistoryComponent } from "./view-histories/view-history/view-history.component";
import { ViewHistoriesComponent } from "./view-histories/view-histories.component";
import { HistoryService } from "../services/history.service";
import { ViewTagsComponent } from "./view-tags/view-tags.component";

const COMPONENTS = [
  NgxValidationMessageComponent,
  // NgxFilterByNumberComponent,
  UploadImageComponent,
  ViewImageComponent,
  ViewVideoComponent,
  DialogYesNoPromptComponent,
  DialogTitlePromptComponent,
  ViewTimeAgoComponent,
  ViewTagsComponent,
  // ViewProfileComponent,
  // DialogProfileComponent,
  ViewPlaceholderComponent,

  ViewGalleryComponent,
  DialogCustomerComponent,
  ViewCustomersComponent,
  ViewCustomerComponent,

  DialogHistoryComponent,
  ViewHistoriesComponent,
  ViewHistoryComponent,
];

const ENTRY_COMPONENTS = [
  // NgxFilterByNumberComponent,
  DialogYesNoPromptComponent,
  DialogTitlePromptComponent,
];

@NgModule({
  imports: [
    ThemeModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NbCardModule,
    NbSpinnerModule,
    NbButtonModule,
    NbIconModule,
    NbRadioModule,
    NbActionsModule,
    NbBadgeModule,
    YouTubePlayerModule,
    // CKEditorModule,
    NbProgressBarModule,
    NbAccordionModule,
    NbTagModule,
    NbListModule,
    NbUserModule,
    NbTabsetModule,
    NbInputModule,
    NbFormFieldModule,
    NbCheckboxModule,
    NbSelectModule,
    NbMenuModule,
    NbAlertModule,
    NbIconModule,
    NbLayoutModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  entryComponents: [...ENTRY_COMPONENTS],
  providers: [UserService, CustomerService, HistoryService],
})
export class ComponentsModule {}
