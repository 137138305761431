import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "ngx-view-placeholder",
  templateUrl: "view-placeholder.component.html",
  styleUrls: ["view-placeholder.component.scss"],
})
export class ViewPlaceholderComponent {
  @HostBinding("attr.aria-label")
  label = "Loading";
}
