import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  NbComponentStatus,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";

import { replace, remove, clone, find } from "lodash";
import { History } from "../../models/history.model";
import { UserService } from "../../services/user.service";
import { HistoryService } from "../../services/history.service";
import { User } from "../../models/users";
import { DialogHistoryComponent } from "../dialog-history/dialog-history.component";
import { Customer } from "../../models/customer.model";
import { map } from "rxjs/operators";
import {
  convertTimestamp,
  convertTimestampsPipe,
} from "../../@core/utils/convertTimestamps.service";

@Component({
  selector: "ngx-view-histories",
  styleUrls: ["./view-histories.component.scss"],
  templateUrl: "./view-histories.component.html",
})
export class ViewHistoriesComponent implements OnInit {
  @Input() customerId?: string = "";
  @Input() customer?: Customer = new Customer();
  @Input() mode?: string = ""; //home / customer
  histories?: History[] = [];
  display?: History[] = [];

  pageSize = 3;
  cursor: any;
  done = false;
  loading = false;
  submitted = false;

  strSearch = "";
  uid = "anonymous";
  companyId = "anonymous";
  isAuthenticated: boolean = false;

  constructor(
    private userService: UserService,
    private historyService: HistoryService,
    private router: Router,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe((user: User) => {
      if (user) {
        this.uid = user.uid;
        this.companyId = user.companyId;
        this.isAuthenticated = true;
      } else {
        this.uid = "anonymous";
        this.companyId = "anonymous";
        this.isAuthenticated = false;
      }

      if (this.mode == "customer") {
        this.getCustomerHistories();
      } else {
        this.getNewHistories();
      }
    });
  }

  getMore() {
    this.pageSize = 12;
    this.getCustomerHistories();
  }

  private getCustomerHistories(): void {
    this.loading = true;

    console.log("getCustomerHistories/customerId:", this.customerId);

    this.historyService
      .getCustomerHistories(this.customerId, this.pageSize, this.cursor)
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          this.cursor = querySnapshot.docs[querySnapshot.docs.length - 1];

          querySnapshot.forEach((doc) => {
            let data = new History();
            data = doc.data();
            data.id = doc.id;
            data.createdOn = convertTimestamp(doc.data().createdOn);

            let item = find(this.histories, ["id", data.id]);
            if (item) {
              item = data;
            } else {
              this.histories.push(data);
            }
          });

          this.display = [...this.histories];

          console.log(this.histories);
        } else {
          this.cursor = null;
          this.done = true;
        }

        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  onNew() {
    let lecNew = new History();
    lecNew.customerId = this.customer.id;
    lecNew.fullName = this.customer.fullName;
    lecNew.dobYear = this.customer.dobYear;

    this.dialogService
      .open(DialogHistoryComponent, {
        context: {
          header: "Tạo trạng thái",
          mode: "new",
          history: lecNew,
        },
        closeOnBackdropClick: false,
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.histories = [value, ...this.histories];
          this.display = [...this.histories];
        }
      });
  }

  onEdit(item: History) {
    if (item) {
      for (let i = 0; i < this.histories.length; i++) {
        if (this.histories[i].id == item.id) {
          this.histories[i] = item;
          break;
        }
      }
      this.display = [...this.histories];
    }
  }

  onDelete(value) {
    remove(this.histories, (e) => {
      return e.id == value;
    });
    this.display = [...this.histories];
  }

  //Control event
  onSearch(): void {
    this.display = this.histories.filter(
      function (item) {
        return item.fullName.toLowerCase().includes(this.strSearch);
      },
      { strSearch: this.strSearch }
    );
  }

  private getNewHistories(): void {
    this.loading = true;
    this.historyService
      .getNewHistories(this.uid)
      .snapshotChanges(["added", "removed"])
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        ),
        convertTimestampsPipe()
      )
      .subscribe((data) => {
        this.loading = false;
        this.histories = data;

        this.display = [...this.histories];
      });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 2000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, titleContent, config);
  }
}
