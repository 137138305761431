import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UserService } from "../services/user.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public userService: UserService) {
    this.userService.getCurrentUser().subscribe((userLogin) => {
      if (!userLogin) {
        this.router.navigate(["auth/login"]);
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.userService.getCurrentUser().subscribe((userLogin) => {
      if (!userLogin) {
        this.router.navigate(["auth/login"], {
          queryParams: { returnUrl: state.url },
        });
      }
    });
    return true;
  }
}
