import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ngx-view-tags",
  styleUrls: ["./view-tags.component.scss"],
  templateUrl: "./view-tags.component.html",
})
export class ViewTagsComponent implements OnInit {
  @Input() tags: string;
  trees?: Set<string> = new Set([]);
  @Output() onTagSelected: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    var trees = new Set([]);
    if (this.tags) {
      var tags = JSON.parse(this.tags);
      tags.forEach(function (value) {
        trees.add(value.name);
      });
    }
    this.trees = trees;
  }

  selected(text) {
    if (text) this.onTagSelected.emit(text);
  }
}
