import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NbComponentStatus,
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbTagComponent,
  NbTagInputAddEvent,
  NbToastrService,
} from "@nebular/theme";
import { remove, find } from "lodash";
import { History } from "../../models/history.model";
import EnumItem from "../../models/enum-list.model";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "ngx-dialog-history",
  templateUrl: "dialog-history.component.html",
  styleUrls: ["dialog-history.component.scss"],
})
export class DialogHistoryComponent implements OnInit {
  @Input() header: string;
  @Input() mode: string;
  @Input() history: History = new History();

  status = [
    {
      value: -1,
      name: "Yếu",
    },
    {
      value: 0,
      name: "Bình thường",
    },
    {
      value: 1,
      name: "Khỏe",
    },
  ];

  diaForm: FormGroup;
  submitted = false;
  loading = false;
  edited = false;

  constructor(
    protected ref: NbDialogRef<DialogHistoryComponent>,
    private fb: FormBuilder,
    private historyService: HistoryService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    const stringValidators = [
      Validators.minLength(3),
      Validators.maxLength(500),
    ];

    this.diaForm = this.fb.group({
      description: ["", stringValidators],
      issueDate: [Validators.required],
      value: [Validators.required],
    });

    if (!this.history.value) {
      this.history.value = 0;
    }
    if (!this.history.status) {
      this.history.status = "Bình thường";
    }
    if (!this.history.issueDate) {
      this.history.issueDate = new Date();
    }
  }

  selectedStatus($value) {
    console.log("selectedStatus/item:", $value);
    this.history.value = $value;
    const item = find(this.status, { value: $value });
    this.history.status = item ? item.name : "";
    console.log("selectedStatus/status:", this.history.status);
  }

  cancel() {
    if (this.edited) {
      this.ref.close(this.history);
    } else {
      this.ref.close();
    }
  }

  submit() {
    this.submitted = true;
    this.history.issueDate = this.history.issueDate
      ? this.history.issueDate
      : new Date();
    this.history.status = this.history.status ? this.history.status : "N/A";
    this.history.description = this.history.description
      ? this.history.description
      : "";
    this.history.imagesUrl = this.history.imagesUrl
      ? this.history.imagesUrl
      : [];

    if (this.mode == "edit") {
      this.edit();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.submitted = true;

    console.log("create:", this.history);

    this.historyService
      .create(this.history)
      .then((data) => {
        this.history = data;

        this.loading = false;
        this.submitted = false;

        this.ref.close(this.history);
      })
      .catch((err) => {
        this.loading = false;
        this.submitted = false;
      });
  }

  edit() {
    this.loading = true;
    this.submitted = true;

    this.historyService
      .update(this.history)
      .then((data) => {
        this.loading = false;
        this.submitted = false;

        this.edited = true;
        this.ref.close(this.history);
        this.showToast("success", "Cập nhật bài viết", "thành công");
      })
      .catch((err) => {
        this.loading = false;
        this.submitted = false;
        this.showToast("warning", "Cập nhật bài viết", "thất bại");
      });
  }

  //Images
  addImage(imageURL: string) {
    if (!this.history.imagesUrl) {
      this.history.imagesUrl = [];
    }
    this.history.imagesUrl.push(imageURL);
  }

  deleteImage(item) {
    remove(this.history.imagesUrl, (el) => {
      return el == item;
    });
  }

  // control event
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 2000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, titleContent, config);
  }
}
