import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";
import { NgxAuthComponent } from "./components/auth.component";
import { NgxLoginComponent } from "./components/login/login.component";
import { NgxLogoutComponent } from "./components/logout/logout.component";
import { NgxRegisterComponent } from "./components/register/register.component";

export const routes: Routes = [
  {
    path: "",
    component: NgxAuthComponent,
    children: [
      {
        path: "",
        component: NbAuthComponent,
        children: [
          {
            path: "",
            redirectTo: "login",
            pathMatch: "full",
          },
          {
            path: "login",
            //component: NbLoginComponent,
            component: NgxLoginComponent,
          },
          {
            path: "register",
            //component: NbRegisterComponent,
            component: NgxRegisterComponent,
          },
          {
            path: "logout",
            //component: NbLogoutComponent,
            component: NgxLogoutComponent,
          },
          {
            path: "request-password",
            component: NbRequestPasswordComponent,
          },
          {
            path: "reset-password",
            component: NbResetPasswordComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {}
