import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NbDialogService } from "@nebular/theme";

import { find, remove, clone } from "lodash";

import { Router } from "@angular/router";
import { HistoryService } from "../../../services/history.service";
import { UserService } from "../../../services/user.service";
import { History } from "../../../models/history.model";
import { DialogYesNoPromptComponent } from "../../dialog-yesno-prompt/dialog-yesno-prompt.component";
import { DialogHistoryComponent } from "../../dialog-history/dialog-history.component";

@Component({
  selector: "ngx-view-history",
  styleUrls: ["./view-history.component.scss"],
  templateUrl: "./view-history.component.html",
})
export class ViewHistoryComponent implements OnInit {
  @Input() mode?: string = ""; //admin / develop
  @Input() history: History;
  @Input() isMore? = false;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<any> = new EventEmitter();

  loading = false;
  submitted = false;
  view = "main";
  uid = "";

  constructor(
    public userService: UserService,
    private historyService: HistoryService,
    private dialogService: NbDialogService,
    private router: Router
  ) {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.uid = user.uid;
      } else {
        this.uid = "anonymous";
      }
    });
  }

  ngOnInit() {}

  reply() {
    this.view = "replies";
  }

  closeReply() {
    this.view = "main";
  }

  loadContent(value) {
    this.isMore = value;
  }

  navigateRef(item) {
    if (item.navigateUrl != "") {
      this.router.navigateByUrl(item.navigateUrl);
    }
  }

  delete(item) {
    this.dialogService
      .open(DialogYesNoPromptComponent, {
        context: {
          title: "Bạn có chắc xóa nội dung này?",
        },
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.historyService
            .delete(item.id)
            .then((result) => {
              this.loading = false;
              this.submitted = false;
              this.onDelete.emit(item.id);
            })
            .catch((err) => {
              this.loading = false;
              this.submitted = false;
            });
        }
      });
  }

  edit(item: History) {
    let lecBk = new History();
    lecBk = clone(item);
    this.dialogService
      .open(DialogHistoryComponent, {
        context: {
          header: "Cập nhật bài viết",
          mode: "edit",
          history: lecBk,
        },
        closeOnBackdropClick: false,
      })
      .onClose.subscribe((value) => {
        if (value) {
          item = value;
          this.history = value;
          this.onEdit.emit(value);
        }
      });
  }

  createHistory(item) {
    //
  }

  viewHistory(item) {
    //
  }

  viewProfile(userId) {
    const value = !this.isMore;
    this.loadContent(value);
    // this.dialogService.open(DialogProfileComponent, {
    //   context: {
    //     header: "Thông tin tài khoản",
    //     userId: userId,
    //   },
    //   closeOnBackdropClick: false,
    // });
  }

  //CKEditor
  public editorConfig = {
    toolbar: [["Format", "FontSize", "TextColor", "BGColor", "Image", "Table"]],
    readOnly: true,
    extraPlugins: "autogrow",
    autoGrow_onStartup: true,
    autoGrow_minHeight: 100,
    // autoGrow_maxHeight: 600,
    autoGrow_bottomSpace: 10,
  };
}
