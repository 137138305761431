import { User } from "./users";

export class Customer {
  id?: string;
  email?: string;
  mobile?: string;
  cardId?: string;
  photoURL?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  dob?: Date;
  dobYear?: number;
  gender?: string;
  address?: string;
  description?: string;

  tags?: string;
  imagesUrl?: string[]; //images

  noHistory?: number;

  //default
  author?: User;
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
  isDeleted?: boolean;
  isActive?: boolean;
  visibility?: string;
}
