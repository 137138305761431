import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FileStorageService } from "../../services/file-storage.service";

@Component({
  selector: "ngx-upload-image",
  styleUrls: ["./upload-image.component.scss"],
  templateUrl: "./upload-image.component.html",
})
export class UploadImageComponent implements OnInit {
  @Input() header: string;
  @Input() imageURL: string;
  @Input() size?: string;
  @Input() mode?: string;
  @Output() onUploadSuccess: EventEmitter<any> = new EventEmitter();

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];
  loading = false;
  urlBg =
    "https://kasoft-learning.s3.ap-southeast-1.amazonaws.com/assets/images/image-upload-bg.jpg";

  constructor(private uploadService: FileStorageService) {}

  ngOnInit(): void {
    if (!this.imageURL) this.imageURL = this.urlBg;
    if (!this.header) this.header = "";
    if (!this.size) this.size = "medium";
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;

    this.loading = true;
    this.uploadService.uploadImageS3(formData).subscribe(
      (obj) => {
        this.loading = false;
        this.imageURL = obj;
        this.onUploadSuccess.emit(obj);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onClickBrowseFile() {
    // this.files = [];
    // const fileUpload = this.fileUpload.nativeElement;
    // fileUpload.onchange = () => {
    //   for (let index = 0; index < fileUpload.files.length; index++) {
    //     const file = fileUpload.files[index];
    //     this.files.push({ data: file, inProgress: false, progress: 0 });
    //   }
    //   this.uploadFiles();
    // };
    // fileUpload.click();
  }
}
