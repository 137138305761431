import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from "@angular/fire/firestore";

import * as firebase from "firebase/app";
import "firebase/firestore";

import { environment } from "../../environments/environment";
import { Customer } from "../models/customer.model";
import { User } from "../models/users";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  visibility = environment.visibility;
  userLogin: User;
  private dbPath = "/customers";

  collRef: AngularFirestoreCollection<Customer>;

  constructor(private db: AngularFirestore, private userStore: UserService) {
    this.collRef = db.collection(this.dbPath);

    this.userStore.getCurrentUser().subscribe((user: User) => {
      if (user) {
        this.userLogin = user;
        this.visibility = user.companyId
          ? user.companyId
          : environment.visibility;
      }
    });
  }

  getCustomers(companyId: string, limit?: number, cursor?: any): any {
    var db = firebase.default.firestore();
    var ref = db
      .collection(this.dbPath)
      .where("isDeleted", "==", false)
      .where("isActive", "==", true)
      .where("visibility", "==", companyId)
      .orderBy("updatedOn", "desc");

    ref = limit ? ref.limit(limit) : ref.limit(10);

    if (cursor) {
      ref = ref.startAfter(cursor);
    }

    return ref.get();
  }

  getMyCustomers(uid: string, limit?: number, cursor?: any): any {
    var db = firebase.default.firestore();
    var ref = db
      .collection(this.dbPath)
      .where("isDeleted", "==", false)
      .where("isActive", "==", true)
      .where("createdBy", "==", uid)
      .orderBy("updatedOn", "desc");

    ref = limit ? ref.limit(limit) : ref.limit(10);

    if (cursor) {
      ref = ref.startAfter(cursor);
    }

    return ref.get();
  }

  searchCustomers(uid: string, mobile?: string): any {
    var db = firebase.default.firestore();
    var ref = db
      .collection(this.dbPath)
      .where("isDeleted", "==", false)
      .where("isActive", "==", true)
      .where("createdBy", "==", uid)
      .where("mobile", "==", mobile)
      .orderBy("updatedOn", "desc");

    return ref.get();
  }

  get(id: string) {
    return this.collRef.doc(id).valueChanges();
  }

  getShareCustomer(id: string) {
    var db = firebase.default.firestore();

    // var ref = db.collection(this.dbPath).doc(id);
    var ref = db.collection("/share-customers").doc(id);

    return ref.get();
  }

  getCustomer(id: string) {
    var db = firebase.default.firestore();
    var ref = db.collection(this.dbPath).doc(id);

    return ref.get();
  }

  create(customer: Customer): any {
    customer.isActive = true;
    customer.isDeleted = false;
    customer.createdOn = new Date();
    customer.createdBy = this.userLogin.uid;
    customer.updatedOn = new Date();
    customer.updatedBy = this.userLogin.uid;
    customer.author = this.userLogin;
    customer.visibility = this.visibility;

    return this.collRef.add({ ...customer }).then((docRef) => {
      customer.id = docRef.id;
      return customer;
    });
  }

  update(customer: Customer): Promise<void> {
    const updatedOn = new Date();
    const updatedBy = this.userLogin.uid;
    return this.collRef.doc(customer.id).update({
      email: customer.email,
      mobile: customer.mobile,
      cardId: customer.cardId,
      photoURL: customer.photoURL,
      fullName: customer.fullName,
      firstName: customer.firstName,
      lastName: customer.lastName,
      // dob: customer.dob,
      dobYear: customer.dobYear,
      gender: customer.gender,
      address: customer.address,
      description: customer.description,
      tags: customer.tags,
      imagesUrl: customer.imagesUrl,
      updatedOn: updatedOn,
      updatedBy: updatedBy,
    });
  }

  delete(id: string): Promise<void> {
    const updatedOn = new Date();
    const updatedBy = this.userLogin.uid;
    return this.collRef.doc(id).update({
      isDeleted: true,
      updatedOn: updatedOn,
      updatedBy: updatedBy,
    });
  }
}
