import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  NbComponentStatus,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";

import { convertTimestamp } from "../../@core/utils/convertTimestamps.service";

import { replace, remove, clone, find } from "lodash";
import { Customer } from "../../models/customer.model";
import { UserService } from "../../services/user.service";
import { CustomerService } from "../../services/customer.service";
import { User } from "../../models/users";
import { DialogCustomerComponent } from "../dialog-customer/dialog-customer.component";

@Component({
  selector: "ngx-view-customers",
  styleUrls: ["./view-customers.component.scss"],
  templateUrl: "./view-customers.component.html",
})
export class ViewCustomersComponent implements OnInit {
  @Input() mode?: string = ""; //admin / develop
  customers?: Customer[] = [];
  display?: Customer[] = [];

  pageSize = 3;
  cursor: any;
  done = false;
  loading = false;
  submitted = false;

  strSearch = "";
  uid = "anonymous";
  companyId = "anonymous";

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private router: Router,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe((user: User) => {
      if (user) {
        this.uid = user.uid;
        this.companyId = user.companyId;
      } else {
        this.uid = "anonymous";
        this.companyId = "anonymous";
      }
      this.getCustomers();
    });
  }

  getMore() {
    this.pageSize = 12;
    this.getCustomers();
  }

  getCustomers(): void {
    this.loading = true;

    let subscribe: any;
    if (this.mode == "admin") {
      subscribe = this.customerService.getCustomers(
        this.companyId,
        this.pageSize,
        this.cursor
      );
    } else {
      subscribe = this.customerService.getMyCustomers(
        this.uid,
        this.pageSize,
        this.cursor
      );
    }

    subscribe
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          this.cursor = querySnapshot.docs[querySnapshot.docs.length - 1];

          querySnapshot.forEach((doc) => {
            let data = new Customer();
            data = doc.data();
            data.id = doc.id;
            data.createdOn = convertTimestamp(doc.data().createdOn);

            let item = find(this.customers, ["id", data.id]);
            if (item) {
              item = data;
            } else {
              this.customers.push(data);
            }
          });

          this.display = [...this.customers];

          console.log(this.customers);
        } else {
          this.cursor = null;
          this.done = true;
        }

        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  onNew() {
    let lecNew = new Customer();
    lecNew.gender = "Khác";
    this.dialogService
      .open(DialogCustomerComponent, {
        context: {
          header: "Tạo khách hàng",
          mode: "new",
          customer: lecNew,
        },
        closeOnBackdropClick: false,
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.customers = [value, ...this.customers];
          this.display = [...this.customers];
        }
      });
  }

  onEdit(item: Customer) {
    if (item) {
      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].id == item.id) {
          this.customers[i] = item;
          break;
        }
      }
      this.display = [...this.customers];
    }
  }

  onDelete(value) {
    remove(this.customers, (e) => {
      return e.id == value;
    });
    this.display = [...this.customers];
  }

  searchCustomers(): void {
    this.strSearch = this.strSearch.trim();
    if (this.strSearch != "") {
      this.loading = true;
      this.customerService
        .searchCustomers(this.uid, this.strSearch.trim())
        .then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            this.cursor = querySnapshot.docs[querySnapshot.docs.length - 1];

            let cus: Customer[] = [];
            querySnapshot.forEach((doc) => {
              let data = new Customer();
              data = doc.data();
              data.id = doc.id;
              data.createdOn = convertTimestamp(doc.data().createdOn);

              cus.push(data);
            });

            this.display = [...cus];

            console.log("searchCustomers:", this.display);
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    }
  }

  //Control event
  onSearch(): void {
    this.display = this.customers.filter(
      function (item) {
        return item.mobile.toLowerCase().includes(this.strSearch);
      },
      { strSearch: this.strSearch }
    );
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 2000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, titleContent, config);
  }
}
