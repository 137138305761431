import {
  NbFirebaseGoogleStrategy,
  NbFirebasePasswordStrategy,
} from "@nebular/firebase-auth";

export const authOptions = {
  forms: {
    login: {
      strategy: "password",
      rememberMe: true,
      socialLinks: [],
    },
    register: {
      strategy: "password",
      terms: true,
      socialLinks: [],
    },
    logout: {
      strategy: "password",
    },
    requestPassword: {
      strategy: "password",
      socialLinks: [],
    },
    resetPassword: {
      strategy: "password",
      socialLinks: [],
    },
    validation: {
      password: {
        required: true,
        minLength: 6,
        maxLength: 50,
      },
      email: {
        required: true,
      },
      fullName: {
        required: true,
        minLength: 4,
        maxLength: 20,
      },
    },
  },
  strategies: [
    NbFirebasePasswordStrategy.setup({
      name: "password",
      login: {
        redirect: {
          success: "home",
        },
      },
      register: {
        redirect: {
          success: "profile",
        },
      },
      logout: {
        redirect: {
          success: "home",
        },
      },
      requestPassword: {
        redirect: {
          success: "auth/login",
        },
      },
      resetPassword: {
        redirect: {
          success: "auth/login",
        },
      },
    }),
    NbFirebaseGoogleStrategy.setup({
      name: "google",
    }),
  ],
};
