export interface User {
  uid?: string;
  role?: string;
  displayName?: string;
  email: string;
  photoURL?: string;
  companyId?: string;
}

export class UserProfile {
  uid?: string;
  role?: string;
  displayName?: string;
  email: string;
  photoURL?: string;

  fullName?: string;
  firstName?: string;
  lastName?: string;
  dob?: Date;
  gender?: number;
  mobile?: string;
  address?: string;

  companyId?: string;
  fsId?: string;

  facebook?: string;
  instagram?: string;
  youtube?: string;
  twitter?: string;
  website?: string;

  createdOn?: Date;
  updatedOn?: Date;
}
