import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ngx-view-gallery",
  styleUrls: ["./view-gallery.component.scss"],
  templateUrl: "./view-gallery.component.html",
})
export class ViewGalleryComponent implements OnInit {
  @Input() imagesUrl: string[];

  slideIndex = 1;
  constructor() {}

  ngOnInit(): void {
    if (!this.imagesUrl) {
      this.imagesUrl = [];
      this.imagesUrl.push(
        "https://kasoft-learning.s3.ap-southeast-1.amazonaws.com/Courses/cover-default.png"
      );
      this.currentSlide(1);
    }
  }

  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(n) {
    if (n > this.imagesUrl.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = this.imagesUrl.length;
    }
  }
}
