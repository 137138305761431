import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { NB_AUTH_OPTIONS, NbAuthService, NbAuthResult } from "@nebular/auth";
import { UserService } from "../../../services/user.service";

import { getDeepFromObject } from "../../helpers";

@Component({
  selector: "ngx-logout",
  templateUrl: "./logout.component.html",
})
export class NgxLogoutComponent implements OnInit {
  redirectDelay: number = this.getConfigValue("forms.logout.redirectDelay");
  strategy: string = this.getConfigValue("forms.logout.strategy");

  constructor(
    public userService: UserService,
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected router: Router
  ) {
    this.userService.signOut();
  }

  ngOnInit(): void {
    this.logout(this.strategy);
  }

  logout(strategy: string): void {
    this.service.logout(strategy).subscribe((result: NbAuthResult) => {
      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          //return this.router.navigateByUrl(redirect);
          window.location.href = "/";
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
