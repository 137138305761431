export const environment = {
  production: false,
  functionsUrl: "https://asia-southeast2-kasoft-learning.cloudfunctions.net/",
  apiUrl: "http://learningapi.kasoft.vn/api",
  //apiUrl: "https://localhost:44382/api",
  companyId: "AEF5EBC9-11AF-46B1-B1C7-99C25DB92A3D",
  visibility: "public",
  firebase: {
    apiKey: "AIzaSyDOjATfm5Uw5G5qNqc7RmSJvrVU4mxH6zw",
    authDomain: "kasoft-healthy.firebaseapp.com",
    projectId: "kasoft-healthy",
    storageBucket: "kasoft-healthy.appspot.com",
    messagingSenderId: "824960015708",
    appId: "1:824960015708:web:6b57e49b9bae88b737b599",
    measurementId: "G-RWHS8N5FGJ",
  },
};
