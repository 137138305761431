import { Component, Input, OnInit } from "@angular/core";
import { format, render, cancel, register } from "timeago.js";

@Component({
  selector: "ngx-view-time-ago",
  styleUrls: ["./view-time-ago.component.scss"],
  templateUrl: "./view-time-ago.component.html",
})
export class ViewTimeAgoComponent implements OnInit {
  @Input() currentDate: Date;
  timeAgo = "";

  constructor() {}

  ngOnInit(): void {
    if (!this.currentDate) this.timeAgo = "";
    else {
      //this.timeAgo = format(this.currentDate);

      // format with locale
      this.timeAgo = format(this.currentDate, "vi");
    }
  }
}
